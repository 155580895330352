import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ApiService } from 'src/app/services/api.service';
import { CartService } from 'src/app/services/cart.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuthUser, CarrelloGruppo, CarrelloMembro, CassaInformazioni, CodiceSconto, Gruppo, Membri, Prodotto, PuntoRitiro, Utente } from 'src/app/shared/interface.model';
import { NzFormTooltipIcon } from 'ng-zorro-antd/form';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-cassa-gruppo',
  templateUrl: './cassa-gruppo.component.html',
  styleUrls: ['./cassa-gruppo.component.scss']
})
export class CassaGruppoComponent implements OnInit {
  public disabledDate = (current: Date): boolean => current > new Date();

  user!: AuthUser;
  membroSelezionato?: Membri;
  gruppo!: Gruppo;

  id_gruppo: number = 0;
  totale: number = 0;
  sub_totale: number = 0;
  commissioni: number = 0;
  step: number = 0;

  punti_ritiro: PuntoRitiro[] = [];
  carrello: CarrelloGruppo = { gruppo: 0, carrello: [] };
  contaProdotti: any = {};
  codici_sconto: CodiceSconto[] = [];

  img_profilo: string = '';
  immagine_utente: string = '';

  error_image: boolean = false;
  caricamento: boolean = true;
  gratis: boolean = false;

  giorni: string[] = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'];

  informazione: CassaInformazioni = { ritiro: false, tesseramento: false, assicurativi: [], immagine: false, giornalieri: [], giornalieri_specifici: [], skipass: false, codice_fiscale: false, clienti: [] }
  metodo = { bonifico: true, carta: true, satispay: true, dopo: true };

  fileList: NzUploadFile[] = [];

  infoUtente!: FormGroup;
  formInformazioni!: FormGroup;
  formMetodo!: FormGroup;
  formCodiceSconto!: FormGroup;

  captchaTooltipIcon: NzFormTooltipIcon = {
    type: 'info-circle',
    theme: 'twotone'
  };

  constructor(private _api: ApiService, private _token: TokenStorageService, private _cart: CartService, public _date: DateService, private _route: ActivatedRoute, private _router: Router, private _datePipe: DatePipe, private _notification: NzNotificationService) { }

  ngOnInit(): void {

    window.scrollTo(0, 0);

    this.codici_sconto = [];

    this.infoUtente = new FormGroup({
      nome: new FormControl(null),
      cognome: new FormControl(null),
      data_nascita: new FormControl(null),
      codice_fiscale: new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$')])
    })

    this.formInformazioni = new FormGroup({
      ritiro: new FormControl(null, Validators.required),
      tesseramento: new FormControl(null, Validators.requiredTrue)
    });

    this.formMetodo = new FormGroup({
      metodo: new FormControl(null, Validators.required)
    });

    this.formCodiceSconto = new FormGroup({
      codice: new FormControl(null, Validators.required)
    });

    this.getData();
  }

  getData() {

    // Recupero l'utente e disabilito il form
    this.user = this._token.getUser();

    // Recupero route params (id gruppo)
    this._route.params.subscribe(params => {
      this.id_gruppo = params['id'];

      // Recupero i punti di ritiro
      this._api.getPuntiRitiro().subscribe({
        next: (data) => this.punti_ritiro = [...data],
        error: (err) => this._notification.create('error', 'Errore', 'Qualcosa è andato storto! Ricaricare la pagina e riprovare', { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
        complete: () => {

          // Recupero i dati gruppo
          this._api.getGruppo(this.id_gruppo).subscribe({
            next: (data) => this.gruppo = data,
            error: (err) => this._notification.create('error', 'Errore', 'Qualcosa è andato storto! Ricaricare la pagina e riprovare', { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
            complete: () => {

              // Se non sei il capogruppo torni all'elenco dei gruppi
              if (this.gruppo.id_cliente != this.user!.id) {
                this._router.navigate(['/gruppi']);
              }

              // Recupero i prodotti dei membri nel carrello
              this._cart.subjectCarrelloGruppo.subscribe(cartData => {

                // Se ci sono prodotti procedo altrimenti torni alla selezione dei prodotti
                if (cartData.carrello.length > 0) {

                  this.carrello = cartData;
                  this.totale = 0;
                  this.metodo = { bonifico: true, carta: true, satispay: true, dopo: true };

                  // Ciclo per i clienti nel carrello
                  for (let cart of this.carrello.carrello) {

                    // Conto i prodotti per ogni membro
                    this.contaProdotti[cart.cliente.id] = cart.prodotti.length;

                    // Calcolo il totale
                    cart.prodotti.forEach((prod: Prodotto) => (this.totale += (prod.prezzo + (prod.supplemento || 0))));

                    // Recupero i dati del cliente selezionato (immagini e dati del form)
                    this._api.getUtente(cart.cliente.id).subscribe((utente: Utente) => {

                      // Recupero l'immagine di profilo                    
                      let file_img = utente.immagine_file;
                      let img_name = utente.immagine;

                      cart.cliente.codice_fiscale = utente.codice_fiscale;

                      // Aggiungo le sue info 
                      this.informazione.clienti.push({ immagine: false, cliente: utente.cognome + ' ' + utente.nome, id: utente.id, codice_fiscale: utente.codice_fiscale, alert_codice_fiscale: false, img_name: img_name, file_img: file_img });

                      // Controllo se il cliente deve effettuare un passaggio prima di pagare
                      this.stepZero(this.step);
                    })

                  }

                  this.caricamento = false;

                  // Recupero i metodi che si possono utilizzare
                  this.getMetodo();

                } else {
                  this._router.navigate(['/gruppi/' + this.id_gruppo + '/acquisto'])
                }
              })
            }

          })

        }

      })

    })
  }

  selezMembro(membro: Membri) {

    if (membro === this.membroSelezionato || !membro)
      return;

    this.caricamento = true;
    this.membroSelezionato = membro;

    // Recupero i dati del membro selezionato (immagini e dati del form)
    this._api.getUtente(membro.id).subscribe((utente: Utente) => {

      this.infoUtente.disable();
      if (!utente.codice_fiscale)
        this.infoUtente.get('codice_fiscale')?.enable();

      setTimeout(() => {
        this.caricamento = false;
      }, 500);

      this.infoUtente.patchValue(utente);

      // Setto l'immagine visibile, se già caricata la mostro altrimenti metto quella di default
      this.img_profilo = utente.immagine_file.length > 0 ? utente.immagine_file : '../../assets/img/avatar.jpg';

      // Ciclo per le informazioni dei clienti se trovo il membro selezionato Setto l'immagine visibile con quella salvata nelle info se presente
      this.informazione.clienti.filter(cliente => cliente.id == membro.id).map(cliente => this.img_profilo = cliente.file_img.length > 0 ? cliente.file_img : this.img_profilo)

    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    });
  }

  onUpdate(id: number) {

    let info = this.informazione.clienti.filter(info => info.id == id)
    let cart = this.carrello.carrello.filter(carrello => carrello.cliente.id == id)

    let codice_fiscale = this.infoUtente.get('codice_fiscale')?.value;
    this._api.updateCodiceFiscale(id, codice_fiscale).subscribe({
      error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
      complete: () => {

        this.formInformazioni.removeControl('codice_fiscale_' + id)
        info[0].codice_fiscale = codice_fiscale;
        info[0].alert_codice_fiscale = false;
        cart[0].cliente.codice_fiscale = codice_fiscale;
        this.informazione.codice_fiscale = false;

      }

    });

  }

  stepZero(step: number) {

    this.informazione.giornalieri = [];
    this.informazione.giornalieri_specifici = [];
    this.informazione.assicurativi = [];

    let tot = 0;

    // Rimuovo tutti i controlli nel form delle informazioni tranne quelli richiesti
    for (const controlName in this.formInformazioni.controls) {
      if (controlName != 'ritiro' && controlName != 'immagine' && controlName != 'tesseramento') {
        this.formInformazioni.removeControl(controlName);
      }
    }

    // Ciclo per il carrello
    this.carrello.carrello.map(carrello => {

      // Ciclo per i prodotti
      carrello.prodotti.map(prodotto => {

        // Se il prodotto ha il flag informativo mostro le opzioni del ritiro
        if (prodotto.informativo) {
          this.informazione.ritiro = true;
        }

        // Se c'è il tessermanto mostro la informativa
        if (prodotto.id == 3) {
          this.informazione.tesseramento = true;
        }

        // Se il prodotto ha il flag assicurativo mostro le opzioni delle assicurazione
        if (prodotto.assicurativo) {

          // aggiungo ai prodotti assicurativi da scegliere se non è già presente
          const alreadyExists = this.informazione.assicurativi.find((assicurazione) => assicurazione.id === prodotto.id);
          if (!alreadyExists) { this.informazione.assicurativi.push(prodotto); }

          // rendo obbligatoria la scelta
          this.formInformazioni.addControl('assicurazione_' + prodotto.id, new FormControl(null, Validators.requiredTrue));
        }

        // Se c'è un prodotto della categoria skipass disabilito bonifico e paga in seguito
        if (prodotto.id_categoria == 2) {
          this.informazione.skipass = true;
        }

        // se è un prodotto giornaliero 
        if (prodotto.giornaliero) {

          // parso i giorni se non sono già stati parsati
          try {
            prodotto.giorni = JSON.parse(prodotto.giorni);
          } catch {
            prodotto.giorni = prodotto.giorni;
          }

          // aggiungo ai prodotti giornalieri da scegliere se non è già presente
          const alreadyExists = this.informazione.giornalieri.find((giornaliero) => giornaliero.id === prodotto.id);
          if (!alreadyExists) { this.informazione.giornalieri.push(prodotto); }

          // rendo obbligatoria la scelta
          this.formInformazioni.addControl('giornaliero_' + prodotto.id, new FormControl('', Validators.required));
        }

        // se è un prodotto con date specifiche 
        if (prodotto.date_specifiche) {

          // aggiungo ai prodotti giornalieri da scegliere se non è già presente
          const alreadyExists = this.informazione.giornalieri_specifici.find((giornaliero) => giornaliero.id === prodotto.id);
          if (!alreadyExists) { this.informazione.giornalieri_specifici.push(prodotto); }

          // rendo obbligatoria la scelta
          this.formInformazioni.addControl('giornaliero_specifico_' + prodotto.id, new FormControl('', Validators.required));
        }

        if (!prodotto.giornaliero && !prodotto.date_specifiche)
          tot += prodotto.prezzo

        // Ciclo tra le info dei clienti               
        this.informazione.clienti.filter(cliente => carrello.cliente.id == cliente.id)
          .map(cliente => {
            // Se il prodotto ha il flag fototessera e non ha un'immagine profilo                 
            if (prodotto.fototessera && cliente.file_img.length <= 0) {

              // Mostro le opzioni se non ha inserito l'immagine
              cliente.immagine = cliente.file_img.length > 0 ? false : true;
            }

            if (prodotto.assicurativo && (!carrello.cliente.codice_fiscale || carrello.cliente.codice_fiscale.length <= 0)) {
              cliente.alert_codice_fiscale = true;
              this.informazione.codice_fiscale = true
            }
          })

      })
    })

    // this.commissioni = (tot / 100) * 2;    
    // this.totale += this.commissioni;

    // Se non devo richiedere la conferma del mog tolgo i validators
    if (!this.informazione.tesseramento) {
      this.formInformazioni.get('tesseramento')?.clearValidators();
      this.formInformazioni.get('tesseramento')?.updateValueAndValidity();
    }

    // Se non devo richiedere il punto di ritiro tolgo i validators
    if (!this.informazione.ritiro) {
      this.formInformazioni.get('ritiro')?.clearValidators();
      this.formInformazioni.get('ritiro')?.updateValueAndValidity();
    }

    // Se non c'è l'immagine aggiungo il controllo   
    for (let info_cliente of this.informazione.clienti) {
      if (info_cliente.immagine) {
        this.formInformazioni.addControl('immagine_' + info_cliente.cliente.replace(' ', '_'), new FormControl(null, Validators.required))
        this.informazione.immagine = true;
      }
    }

    // Se tutte le informazioni non sono richieste vai al primo step altrimenti vai allo step richiesto
    if (!this.informazione.ritiro && !this.informazione.tesseramento && this.informazione.assicurativi.length <= 0 && !this.informazione.immagine && this.informazione.giornalieri.length <= 0 && this.informazione.giornalieri_specifici.length <= 0 && !this.informazione.codice_fiscale) {
      this.step = step != 0 ? step : 1;
    } else {
      this.step = step;
    }
  }

  onPdf(id: number) {
    this._api.getPDFAssicurazione(id).subscribe({
      next: (data) => {
        let downloadURL = window.URL.createObjectURL(data);
        let tab = window.open();
        tab!.location.href = downloadURL;
      },
      error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 })
    })
  }

  getMetodo() {

    // Ciclo per il carrello
    this.carrello.carrello.map(carrello => {

      // Ciclo per i prodotti
      carrello.prodotti.map(prodotto => {

        // Se c'è un prodotto della categoria skipass disabilito bonifico e paga in seguito
        if (prodotto.id_categoria == 2) {
          this.metodo.bonifico = false;
        }
        // Se non è presente il flag per i bonifici disabilito il metodo
        if (!prodotto.bonifico) {
          this.metodo.bonifico = false;
        }
        // Se non è presente il flag per la carta disabilito il metodo
        if (!prodotto.carta) {
          this.metodo.carta = false;
        }
        // Se non è presente il flag per satispay disabilito il metodo
        if (!prodotto.satispay) {
          this.metodo.satispay = false;
        }

        if (prodotto.obbligatorio) {
          this.metodo.dopo = false;
          this.metodo.bonifico = false;
        }
      })

    })

  }

  beforeUpload = (file: any): boolean => {

    // Controllo se il file caricato è un'immagine
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    // Se è un immagine procedo
    if (isJpgOrPng) {

      const reader = new FileReader();
      reader.onload = () => {

        // Salvo il nome e il base64 del file        
        this.img_profilo = reader.result as string;
        this.error_image = false;

        // Ciclo per le info dei clienti
        for (let info_cliente of this.informazione.clienti) {

          // Se trovo il match tra il membro selezionato e la info del cliente
          if (this.membroSelezionato!.id == info_cliente.id) {

            // Salvo il nome del file e il bas64 e rimuovo il controllo
            info_cliente.immagine = false;
            info_cliente.img_name = file.name;
            info_cliente.file_img = reader.result as string;
            this.formInformazioni.removeControl('immagine_' + info_cliente.cliente.replace(' ', '_'));
          }
        }

      }
      reader.readAsDataURL(file);
    } else {
      this.error_image = true;
    }

    return false;
  };

  onMostraImmagine(id_membro: number) {
    let result = false;

    // Ciclo tra le info dei clienti    
    this.informazione.clienti.filter(cliente => cliente.id == id_membro).map(cliente => {
      // Se il flag immagine è attivo
      if (cliente.immagine) {
        result = true;
        return;
      }
    })

    return result;
  }

  onRimuoviProdotto(prodotto: Prodotto, cliente: Membri) {

    // Ciclo per i membri nel carrello
    this.carrello.carrello.filter(carrello => cliente.id == carrello.cliente.id).map(cart => {

      // Se il prodotto è obbligatorio, quando viene rimosso tolgo tutto
      if (prodotto.obbligatorio) {

        cart.prodotti = [];

        // Rimuovo il cliente dal carrello perchè non ha prodotti selezionati
        let updatedMembri = this.carrello.carrello.filter((carrello: CarrelloMembro) => carrello.cliente.id !== cliente.id);
        this.carrello.carrello = updatedMembri;

        this._cart.subjectCarrelloGruppo.next(this.carrello);

        this.contaProdotti[cart.cliente.id] = cart.prodotti.length;
        this._notification.create('warining', 'Prodotti rimossi dal carrello', `È stato rimosso il tesseramento dello SciClub dal carrello, tutti gli altri prodotti sono stati rimossi!`, { nzPlacement: 'bottomLeft', nzDuration: 3000 });
        return;
      } else {

        // Creo nuovo array con il prodotto rimosso e lo salvo 
        let updatedProducts = cart.prodotti.filter((prod: Prodotto) => prod.id !== prodotto.id);
        cart.prodotti = updatedProducts;

        // Se non ci sono più prodotti rimuovo anche il cliente
        if (updatedProducts.length <= 0) {
          let updatedMembri = this.carrello.carrello.filter((carrello: CarrelloMembro) => carrello.cliente.id !== cliente.id);
          this.carrello.carrello = updatedMembri;
        }

        let prodotti = cart.prodotti.filter((prodotto: Prodotto) => prodotto.informativo)
        if (prodotti.length <= 0)
          this.formInformazioni.patchValue({ ritiro: null })

        this._cart.subjectCarrelloGruppo.next(this.carrello);
        this.contaProdotti[cart.cliente.id] = cart.prodotti.length;
        this._notification.create('info', 'Prodotto rimosso dal carrello', `${prodotto.nome} è stato rimosso con successo dal carrello`, { nzPlacement: 'bottomLeft', nzDuration: 1000 });
        return;
      }

    })

  }

  onRimuoviImmagine(id_membro: number) {

    // Ciclo tra le info dei clienti
    this.informazione.clienti.filter(cliente => cliente.id == id_membro).map(cliente => {
      // Se l'immagine dell'utente è salvata sul server        
      if (cliente.file_img.length > 0) {

        // Elimino il file salvato
        this._api.deleteImmagineUtente(id_membro).subscribe({
          error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
          complete: () => {
            this.img_profilo = '../../assets/img/avatar.jpg';
            cliente.immagine = true;
            cliente.file_img = '';
            cliente.img_name = '';
          }

        })
      } else {

        // Elimino il file presente nel file upload
        this.img_profilo = '../../assets/img/avatar.jpg';
        cliente.immagine = true;
        cliente.file_img = '';
        cliente.img_name = '';
      }
    })

  }

  onRimuoviCodiceSconto(id: number): void {

    // Recupero il subtotale dal totale
    this.sub_totale = this.totale;

    // Aggiorno i codici sconto salvati togliendo quello rimosso
    this.codici_sconto = this.codici_sconto.filter(codice => codice.id != id).map(codice => {
      this.sub_totale -= (codice.valore)
      return codice;
    })

    // Controllo se il subtotale sia a zero, abilito il invio ordine
    this.gratis = this.sub_totale <= 0;
  }

  onProssimoStep() {
    // Vai allo step successivo
    window.scrollTo(0, 0);
    this.onInfo();
    this.step++;
  }

  onInfo() {

    this.totale = 0;

    // Ciclo tra i clienti nel carrello
    this.carrello.carrello.map(cart => {

      // Ciclo tra le info dei clienti
      this.informazione.clienti.filter(cliente => cart.cliente.id == cliente.id).map(cliente => {

        // Aggiungo nel carrello i dati dell'immagine (nome e base64)
        cart.cliente.immagine = cliente.img_name?.length > 0 ? cliente.img_name : cart.cliente.immagine;
        cart.cliente.immagine_file = cliente.file_img.length > 0 ? cliente.file_img : cart.cliente.immagine_file;
      })


      //ciclo nei prodotti
      cart.prodotti.map(prodotto => {

        // ciclo nei giornalieri
        this.informazione.giornalieri.filter(giornaliero => prodotto.id == giornaliero.id).map(giornaliero => {

          let giorno = this.formInformazioni.get('giornaliero_' + prodotto.id)?.value;
          prodotto.data_utilizzo = this._datePipe.transform(giorno, 'yyyy-MM-dd');

          this._date.checkSupplemento(giorno, prodotto)
        })

        // ciclo nei giornalieri specifici
        this.informazione.giornalieri_specifici.filter(giornaliero => prodotto.id == giornaliero.id).map(giornaliero => {

          let giorno = this.formInformazioni.get('giornaliero_specifico_' + prodotto.id)?.value;
          prodotto.data_utilizzo = this._datePipe.transform(giorno, 'yyyy-MM-dd');

          this._date.checkSupplemento(giorno, prodotto)

        })
      })

      // Calcolo il totale
      cart.prodotti.forEach((prod: Prodotto) => (this.totale += (prod.prezzo + (prod.supplemento || 0))));
    })

    // this.totale += this.commissioni;
  }  

  onCoupon() {

    const codice = this.formCodiceSconto.get('codice')?.value;

    // Recupero i dati del codice sconto
    this._api.getCodiceScontoGruppo(codice.trim(), this.carrello).subscribe(codice => {

      // Controllo se l'ordine è già con totale a zero
      if (this.gratis) {
        this._notification.create('warning', 'Totale carrello uguale a zero!', 'Il codice sconto non può essere applicato perchè il totale del carrello è già uguale a zero!', { nzPlacement: 'bottomLeft', nzDuration: 7500 });
        return;
      }

      // Valorizzo il sub totale con il totale
      this.sub_totale = this.totale;
      // Calcolo il subtotale
      this.codici_sconto.forEach((codice: CodiceSconto) => (this.sub_totale -= (codice.valore)));

      // Se il totale cliente è minore o uguale al valore del codice setto il valore del codice uguale al totale cliente
      codice.valore = this.sub_totale <= codice.valore ? this.sub_totale : codice.valore

      // Controllo se il codice sconto è già stato applicato
      const alreadyExists = this.codici_sconto.find((codice_sconto) => codice_sconto.id === codice.id);
      if (!alreadyExists) { this.codici_sconto.push(codice); }
      else {
        this._notification.create('warning', 'Codice Sconto già applicato!', `Il codice sconto inserito è gia stato applicato al carrello`, { nzPlacement: 'bottomLeft', nzDuration: 7500 });
        return;
      }

      // Valorizzo il sub totale con il totale
      this.sub_totale = this.totale;
      // Calcolo il subtotale
      this.codici_sconto.forEach((codice: CodiceSconto) => (this.sub_totale -= (codice.valore)));

      // Controllo se il subtotale sia a zero, abilito il invio ordine
      this.gratis = this.sub_totale <= 0;

      // Azzero il form
      this.formCodiceSconto.setValue({ codice: null });


    }, err => {
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    })
  }

  onPagamentoInSeguito() {

    this.caricamento = true;

    // recupero il metodo di pagamento
    let metodo = !this.gratis ? this.formMetodo.get('metodo')?.value : "Coupon";

    // formo l'ordine inserendo i dati inseriti dal cliente
    let ordine = {
      metodo: metodo || '',
      nota: this.formInformazioni.get('ritiro')?.value ? 'Ritiro presso: ' + this.formInformazioni.get('ritiro')?.value : '',
      eseguitoDa: this.user?.cognome + ' ' + this.user!.nome,
      id_gruppo: this.id_gruppo,
      gruppo: this.gruppo.nome,
      carrello: this.carrello.carrello,
      codici_sconto: this.gratis ? this.codici_sconto : [],
      token: null,
      stato: this.gratis ? 'pagato' : 'non pagato'
    }

    this._api.addOrdine(ordine).subscribe(data => {
      if (data.code == 200) {
        this.caricamento = false;
        this._cart.clearCartGruppo();
        if (this.gratis)
          this._router.navigate(['/esito-pagamento-dopo'], { queryParams: { ordine: true } });
        else
          this._router.navigate(['/esito-pagamento-dopo']);

      } else if (data.code == 300) {
        this.caricamento = false;
        this._cart.clearCartGruppo();
        if (this.gratis)
          this._router.navigate(['/esito-pagamento-dopo'], { queryParams: { ordine: true } });
        else
          this._router.navigate(['/esito-pagamento-dopo']);

      }
    }, err => {
      this.caricamento = false;
      this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
    });

  }

  onPagamento() {
    this.caricamento = true;

    // recupero il metodo di pagamento
    let metodo = !this.gratis ? this.formMetodo.get('metodo')?.value : "Coupon";

    // Creo l'oggetto con l'ordine
    let ordine = {
      metodo: metodo,
      note: this.formInformazioni.get('ritiro')?.value ? 'Ritiro presso: ' + this.formInformazioni.get('ritiro')?.value : '',
      eseguitoDa: this.user?.cognome + ' ' + this.user?.nome,
      id_gruppo: this.id_gruppo,
      gruppo: this.gruppo.nome,
      carrello: this.carrello.carrello,
      codici_sconto: this.codici_sconto,
    }

    if (metodo === 'Bonifico') {
      this.onPagamentoInSeguito();
    } else if (metodo === 'Dopo') {
      this.onPagamentoInSeguito();
    } else if (metodo === 'Coupon') {
      this.onPagamentoInSeguito();
    } else if (metodo == 'Carta di credito') {

      // Recupero l'url di pagamento di stripe con carta
      this._api.getPagamentoCarta(ordine).subscribe(data => {
        localStorage.setItem('token_pagamento', data.token_pagamento);
        window.location.href = data.url;
        this.caricamento = false;
      }, err => {
        this.caricamento = false;
        this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
      })

      // Se è stato selezionato satispay
    } else if (metodo == 'Satispay') {

      // Recupero l'url di pagamento di satispay
      this._api.getPagamentoSatispay(ordine).subscribe(data => {
        localStorage.setItem('satispay', data.satispay);
        window.location.href = data.url;
        this.caricamento = false;
      }, err => {
        this.caricamento = false;
        this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
      })

    }
  }

}