<nz-spin [nzSpinning]="caricamento" class="spinning">
  <div class="container border mb-5 p-0">    
    <div class="row p-lg-3 p-1 py-3 m-0">
      <div class="col">
        <h4 class="mb-2">Cassa</h4>
        <p class="pb-0 mb-0">Completa le informazioni richieste e seleziona il metodo di pagamento!</p>
      </div>
    </div>
    <div class="row border-top m-0 p-0">      
      
        <ng-container *ngIf="step == 0">
          <div class="border-end m-0 px-lg-0 py-3 col-lg-4">
            <ul class="list-group list-group-flush">
              <li class="list-group-item p-0 cursor-pointer" *ngFor="let cart of carrello.carrello" (click)="selezMembro(cart.cliente)" [class.selezionato]="cart.cliente.id == membroSelezionato?.id">
                <div class="cliente p-lg-3 p-2">
                  <span>{{cart.cliente.nome}} {{cart.cliente.cognome}}
                    <i *ngIf="gruppo?.id_cliente == cart.cliente?.id" style="transform: translateY(-25%);" class="fs-6" nz-icon nzType="crown" nzTheme="outline"></i>
                    <span class="badge rounded-pill bg-light border text-black float-end" style="transform: translateY(-10%)">{{contaProdotti[cart.cliente.id] ? contaProdotti[cart.cliente.id] + (contaProdotti[cart.cliente.id] == 1 ? ' prodotto' : ' prodotti') : 'Aggiungi un prodotto'}}</span>
                  </span>
                  <form id="infoUtente" [formGroup]="infoUtente" *ngIf="membroSelezionato && cart.cliente.id == membroSelezionato.id">
                    <div class="row mb-3 mt-3">
                      <div class="col">
                        <div [ngStyle]="{'background-image':'url('+ img_profilo +')'}" class="rounded-circle border" style="background-size:cover; height: 104px; aspect-ratio: 1/1; margin: 0px auto;"></div>
                      </div>
                    </div>
                    <div class="row" *ngIf="img_profilo != '../../assets/img/avatar.jpg'">
                      <div class="col text-center">
                        <a nz-popconfirm nzPopconfirmTitle="Sicuro di voler eliminare l'immagine?" (nzOnConfirm)="onRimuoviImmagine(cart.cliente.id)" class="text-muted small text-center">Elimina</a>
                      </div>
                    </div>
                    <div class="row mb-1 mt-3">
                      <div class="col-md-6"><input formControlName="nome" nz-input placeholder="Nome" /></div>
                      <div class="col-md-6 mt-md-0 mt-2"><input formControlName="cognome" nz-input placeholder="Cognome" /></div>
                    </div>
                    <div class="row mb-1">
                      <div class="col"><nz-date-picker [nzDisabledDate]="disabledDate" formControlName="data_nascita" nzFormat="dd-MM-yyyy" class="w-100 data_membro"></nz-date-picker></div>
                    </div>
                    <div class="row mb-1">
                      <div class="col"><input formControlName="codice_fiscale" nz-input placeholder="Codice Fiscale"/></div>
                    </div>
                    <div class="row mt-3 mb-3" *ngIf="onMostraImmagine(cart.cliente.id)">
                      <nz-upload [(nzFileList)]="fileList" [nzShowButton]="fileList.length <= 0" [nzBeforeUpload]="beforeUpload" nzAccept="image/*">
                        <button nz-button type="button">
                          <span class="align-middle" nz-icon nzType="upload"></span>
                          Carica immagine
                        </button>
                      </nz-upload>
                    </div>
                  
                    <div class="row mt-3" *ngIf="cart.cliente.codice_fiscale.length <= 0">
                      <div class="col">
                        <button nz-button  class="w-100" type="button" [disabled]="!infoUtente.valid" (click)="onUpdate(cart.cliente.id)">Aggiorna dati</button>
                      </div>
                    </div>              
                  </form>
                </div>
              </li>
            </ul>
          </div>
          <div class="p-3 col-lg-8">
            <div class="billing-container info-container p-3">
              <div class="form-container d-flex align-items-center">              
                <form [formGroup]="formInformazioni" class="w-100">

                  <h4 class="mb-4">Informazioni</h4>

                  <div class="info-immagine" *ngFor="let info_cliente of informazione.clienti">                                    
                    <nz-alert nzType="error" nzShowIcon [nzMessage]="'Il membro: ' + info_cliente.cliente + ' non ha un codice fiscale! Per procedere clicca sul membro nella colonna di sinistra, aggiorna il campo e premi sul pulsante sotto i dati!'" [ngStyle]="{ marginBottom: '12px' }" *ngIf="info_cliente.alert_codice_fiscale"></nz-alert>
                    <nz-alert nzType="error" nzShowIcon [nzMessage]="'Il membro: ' + info_cliente.cliente + ' deve avere un`immagine profilo per acquistare i prodotti della vialattea! Per procedere clicca sul membro nella colonna di sinistra e premi sul pulsante sotto i suoi dati!'" [ngStyle]="{ marginBottom: '12px' }" *ngIf="info_cliente.immagine"></nz-alert>
                    <nz-alert nzType="error" nzShowIcon [nzMessage]="'Inserire un file di tipo PNG, JPG o JPEG com immagine di profilo'" class="mt-2 mb-2" *ngIf="error_image"></nz-alert>
                  </div>

                  <div class="info-ritiro" *ngIf="informazione.ritiro">
                    <p class="titolo-info">Inserire un punto di ritiro per le tessere:</p>
                    <nz-radio-group formControlName="ritiro">
                      <label nz-radio *ngFor="let punto_ritiro of punti_ritiro" [nzValue]="punto_ritiro.valore">{{punto_ritiro.descrizione}}</label>                    
                    </nz-radio-group>
                  </div>

                  <div class="info-tesseramento mt-3" *ngIf="informazione.tesseramento">
                    <p class="titolo-info">Modulo di accettazione e presa visione del MOG e codice Condotta:</p>                
                    <label nz-checkbox class="mb-2" formControlName="tesseramento">* Dichiaro di aver preso visione e letto le direttive del</label> <a style="font-size: 14px; margin-left: 5px;" href="../../../assets/pdf/mog 2024.pdf" target="_blank">MOG</a> <span style="font-size: 14px;"> e del </span> <a style="font-size: 14px;" href="../../../assets/pdf/codice-condotta.pdf" target="_blank">Codice di condotta</a>
                  </div>              

                  <div class="info-assicurazione mt-3" *ngIf="informazione.assicurativi.length > 0">
                    <p class="titolo-info">Modulo di accettazione e presa visione dell'assicurazione:</p>
                    <div class="checkbox-container" *ngFor="let assicurazione of informazione.assicurativi">                                        
                      <label nz-checkbox class="mb-2" [formControlName]="'assicurazione_' + assicurazione.id">* Dichiaro di aver preso visione e letto le direttive dell'<a style="font-size: 14px;" class="mb-2" (click)="onPdf(assicurazione.id)" target="_blank">{{assicurazione.nome}}</a></label>
                    </div>
                  </div>

                  <div class="giornalieri" *ngIf="informazione.giornalieri.length > 0">
                    <nz-form-item class="flex-column mb-2" *ngFor="let prodotto of informazione.giornalieri">
                        <nz-form-label class="text-start" nzRequired [nzTooltipIcon]="captchaTooltipIcon" nzTooltipTitle="Il prodotto Giornaliero può essere acquistato per il giorno stesso, fino alle ore 8 del mattino!">Scegli per quale giorno attivare il prodotto <strong class="ms-1">{{prodotto.nome}}</strong></nz-form-label>
                        <nz-form-control>                        
                            <nz-date-picker [formControlName]="'giornaliero_' + prodotto.id" class="w-100" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" [nzShowToday]="false" [nzDisabledDate]="_date.disableDateGiornalieri(prodotto.giorni, prodotto.inizio_validita, prodotto.fine_validita)"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                  </div>

                  <div class="giornalieri_specifici" *ngIf="informazione.giornalieri_specifici.length > 0">
                    <nz-form-item class="flex-column mb-2" *ngFor="let prodotto of informazione.giornalieri_specifici">
                        <nz-form-label class="text-start" nzRequired [nzTooltipIcon]="captchaTooltipIcon" nzTooltipTitle="Il prodotto Giornaliero può essere acquistato per il giorno stesso, fino alle ore 8 del mattino!">Scegli per quale giorno attivare il prodotto <strong class="ms-1">{{prodotto.nome}}</strong></nz-form-label>
                        <nz-form-control>                        
                            <nz-date-picker [formControlName]="'giornaliero_specifico_' + prodotto.id" class="w-100" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" [nzShowToday]="false" [nzDisabledDate]="_date.disableDateGiornalieriSpecifici(prodotto.date_specifiche_dal, prodotto.date_specifiche_al)"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                  </div>
                </form>
              </div>
            </div>  
          </div>
        </ng-container>
        <ng-container *ngIf="step == 1">          
          <div class="col-lg-4 border-end p-3 m-0 metodi-container">
            <div class="form-container p-lg-3 p-0">
              <h4 class="mb-4">Metodo di pagamento</h4>
              <form [formGroup]="formMetodo">
                <nz-radio-group formControlName="metodo">
                  <label nz-radio class="mt-0 mb-2" *ngIf="metodo.carta" nzValue="Carta di credito">Carta di credito</label>
                  <label nz-radio class="mt-0 mb-2" *ngIf="!metodo.carta" [nzDisabled]="true" nz-tooltip nzTooltipTitle="Il metodo 'Carta di Credito' non può essere utilizzato perché alcuni prodotti nel carrello, non consentono questo metodo di pagamento" nzTooltipPlacement="top" nzValue="Carta di credito">Carta di credito</label>
                  <label nz-radio class="mt-0 mb-2" *ngIf="metodo.satispay" nzValue="Satispay">Satispay</label>
                  <label nz-radio class="mt-0 mb-2" *ngIf="!metodo.satispay" [nzDisabled]="true" nz-tooltip nzTooltipTitle="Il metodo 'Satispay' non può essere utilizzato perché alcuni prodotti nel carrello, non consentono questo metodo di pagamento" nzTooltipPlacement="top" nzValue="Satispay">Satispay</label>
                  <label nz-radio class="mt-0 mb-2" *ngIf="metodo.bonifico && informazione.giornalieri.length <= 0 && informazione.giornalieri_specifici.length <= 0 && codici_sconto.length <= 0" nzValue="Bonifico" nz-tooltip nzTooltipTitle="Attenzione! Selezionando l'opzione bonifico, i prodotti non saranno attivi finchè non verrà accertato l'avvenuto pagamento. Questo può richiedere fino a due giorni lavorativi. Puoi completare il pagamento nella sezione 'I miei ordini'." [nzTooltipColor]="'blue'">Bonifico <i class="text-muted bi bi-exclamation-triangle-fill"></i></label>
                  <label nz-radio class="mt-0 mb-2" *ngIf="!metodo.bonifico || informazione.giornalieri.length > 0 || informazione.giornalieri_specifici.length > 0 || codici_sconto.length > 0" [nzDisabled]="true" nz-tooltip nzTooltipTitle="Il metodo 'Bonifico' non può essere utilizzato perché alcuni prodotti nel carrello, non consentono questo metodo di pagamento" nzTooltipPlacement="top" nzValue="Bonifico">Bonifico</label>
                  <label nz-radio class="mt-0 mb-2" *ngIf="metodo.dopo && informazione.giornalieri.length <= 0 && informazione.giornalieri_specifici.length <= 0 && !informazione.skipass && codici_sconto.length <= 0" nzValue="Dopo" nz-tooltip nzTooltipTitle="Attenzione! Selezionando l'opzione 'Pagamento in seguito', i prodotti non saranno attivi finchè non verranno pagati!. Puoi completare il pagamento nella sezione 'I miei ordini'." [nzTooltipColor]="'blue'">Paga in seguito</label>
                  <label nz-radio class="mt-0 mb-2" *ngIf="!metodo.dopo || informazione.giornalieri.length > 0 || informazione.giornalieri_specifici.length > 0 || informazione.skipass || codici_sconto.length > 0" [nzDisabled]="true" nzValue="Dopo" nz-tooltip nzTooltipTitle="Il metodo 'Paga in seguito' non può essere utilizzato perché alcuni prodotti nel carrello, non consentono questo metodo di pagamento" nzTooltipPlacement="top">Paga in seguito</label>
                </nz-radio-group>
              </form>                  
            </div>
          </div>
          <div class="col-lg-8 p-3 m-0 riepilogo-container">
            <div class="billing-container">                                      
              <div class="form-container p-lg-3 p-0">
                <h4 class="mb-4">Riepilogo</h4>
                <ul class="list-group list-group rounded-0">
                  <li *ngFor="let cart of carrello.carrello;">
                    <ul class="list-group list-group rounded-0">
                      <li class="list-group-item fw-bold text-white bg-primary">Prodotti di: {{cart.cliente.cognome}} {{cart.cliente.nome}}</li>
                      <li class="list-group-item" *ngFor="let prodotto of cart.prodotti" nz-tooltip [nzTooltipTitle]="prodotto.supplemento ? 'Il prodotto ha avuto un supplemento perché è stato acquistato per il giorno seguente in queste fascie di orario: Dalle 21:01 alle 23:00 - 0,50€. Dalle 23:01 alle 8:00 - 2,00€': ''">{{prodotto.nome}} | {{prodotto.prezzo | currency: 'EUR'}} {{prodotto.supplemento ? ' + ' + (prodotto.supplemento | currency: 'EUR') : ''}}                        
                        <span class="ms-2 fs-5 text-muted float-end delete cursor-pointer" nz-popconfirm nzPopconfirmTitle="Sei sicuro di volere rimuovere il prodotto dal carrello?" nz-icon nzType="delete" nzTheme="outline" (nzOnConfirm)="onRimuoviProdotto(prodotto, cart.cliente)"></span>
                      </li>                      
                                          
                    </ul>
                  </li>
                  <li class="list-group-item fw-bold text-white bg-primary" *ngIf="codici_sconto.length > 0">Codici Sconto</li>
                  <div class="codice" *ngFor="let codice_sconto of codici_sconto">
                    <li class="list-group-item"><span>Codice sconto: <strong>{{codice_sconto.codice}}</strong> - {{codice_sconto.valore | currency: 'EUR'}}</span>
                      <span class="ms-2 fs-5 text-muted float-end delete cursor-pointer" nz-popconfirm nzPopconfirmTitle="Sei sicuro di volere rimuovere il codice sconto dal carrello?" nz-icon nzType="delete" nzTheme="outline" (nzOnConfirm)="onRimuoviCodiceSconto(codice_sconto.id)"></span>
                    </li>               
                  </div>
                  <li class="list-group-item fw-bold text-white bg-primary"><i style="visibility: hidden;">text</i></li>
                  <li class="list-group-item" *ngIf="codici_sconto.length > 0"><strong>Totale Scontato</strong>: {{sub_totale | currency: 'EUR'}}</li>
                  <!-- <li class="list-group-item" *ngIf="commissioni > 0"><strong>Commissioni</strong>: {{commissioni | currency: 'EUR'}}</li> -->                  
                  <li class="list-group-item"><strong>Totale</strong>: <span [ngStyle]="{'text-decoration': codici_sconto.length <= 0 ? 'none' : 'line-through'}">{{totale | currency: 'EUR'}}</span></li>                         
                </ul>  
                

              </div>
            </div>

            <div class="p-lg-3 mt-lg-0 mt-5 p-0 codici-container">
              <form [formGroup]="formCodiceSconto" class="w-100">
                <div class="row">
                  <div class="col">
                    <h4 class="mb-4">Hai un codice sconto? <span nz-icon class="info" nzType="info-circle" nzTheme="outline" [routerLink]="'/gruppi/' + id_gruppo" nz-tooltip nzTooltipTitle="Clicca sull'icona e vai alla sezione 'Buoni Sconto' per vedere i codici sconto legati al tuo account"></span> </h4>            
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-md-10">
                    <input nz-input placeholder="Inserisci qui il tuo coupon" nzSize="large" formControlName="codice" />
                  </div>
                  <div class="col-md-2">
                    <button class="d-inline btn btn-primary rounded-0 w-100" nz-tooltip [nzTooltipTitle]="!formCodiceSconto.valid ? 'Inserire un codice sconto per applicarlo!' : ''" [disabled]="!formCodiceSconto.valid" type="button" (click)="onCoupon()">Applica</button>
                  </div>
                </div>                            
              </form>          
            </div>
          </div>

        </ng-container>
      </div>    
    <div class="row p-lg-3 p-1 py-3 border-top m-0">      
      <div class="col d-flex align-items-center justify-content-end checkout-desktop gap-2">        
        <h6 *ngIf="step == 0" class="my-auto d-inline me-3"><span class="fw-normal">Totale: </span>{{totale| currency: 'EUR'}}</h6>        
        <button type="button" class="d-inline btn rounded-0 border"  [routerLink]="'/gruppi/' + id_gruppo + '/acquisto'">Torna all'acquisto</button>
        <button type="button" *ngIf="step == 1 && (informazione.assicurativi.length > 0 || informazione.ritiro || informazione.tesseramento || informazione.immagine || informazione.giornalieri.length > 0 || informazione.giornalieri_specifici.length > 0)" class="d-inline btn rounded-0 border " (click)="stepZero(0)">Indietro</button>
        <button type="button" *ngIf="step == 1" class="d-inline btn btn-primary rounded-0 " [disabled]="!formMetodo.valid && !gratis" nz-tooltip [nzTooltipTitle]="gratis || formMetodo.valid ? '' : 'Selezionare un metodo di pagamento per procedere con l\'ordine'" (click)="onPagamento()">Invia l'ordine</button>        
        <button type="button" *ngIf="step == 0" class="d-inline btn btn-primary rounded-0 " [disabled]="!formInformazioni.valid || informazione.codice_fiscale" (click)="onProssimoStep()">Continua</button>
      </div>
      <div class="col checkout-mobile">        
        <h6 *ngIf="step == 0" class="my-auto d-inline me-3"><span class="fw-normal">Totale: </span>{{totale| currency: 'EUR'}}</h6>
        <button type="button" class="d-inline btn rounded-0 border col mb-0 mt-3 bottone"  [routerLink]="'/gruppi/' + id_gruppo + '/acquisto'">Torna all'acquisto</button>
        <button type="button" class="d-inline btn rounded-0 border col mb-0 bottone" *ngIf="step == 1 && (informazione.assicurativi.length > 0 || informazione.ritiro || informazione.tesseramento || informazione.immagine || informazione.giornalieri.length > 0 || informazione.giornalieri_specifici.length > 0)" (click)="stepZero(0)">Indietro</button>
        <button type="button" *ngIf="step == 1" class="d-inline btn btn-primary rounded-0 mb-0 col bottone" [disabled]="!formMetodo.valid && !gratis" nz-tooltip [nzTooltipTitle]="gratis || formMetodo.valid ? '' : 'Selezionare un metodo di pagamento per procedere con l\'ordine'" (click)="onPagamento()">Invia l'ordine</button>        
        <button type="button" *ngIf="step == 0" class="d-inline btn btn-primary rounded-0 mb-0 col bottone" [disabled]="!formInformazioni.valid || informazione.codice_fiscale" (click)="onProssimoStep()">Continua</button>
      </div>
    </div>
  </div>
</nz-spin>